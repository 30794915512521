import type { ArticleDTO } from '~/typesAuto/apicore/v1'
import type { JobAdListDto } from '~/typesAuto/jobshot/v1'
import type { EventPerson } from '~/typesManual/eventPerson'
import type { EcommerceItem } from '~/typesManual/dataLayer'

const baseEcommerce = (items: EcommerceItem[], currency: string) => ({
  ecommerce: {
    currency: currency,
    items,
  },
})

export default function () {
  const $gtm = useGtm()
  const userStore = useUserStore()
  const config = useRuntimeConfig()

  const basePayload = {
    userId: userStore.user?.userId,
    clientId: userStore.user?.clientId,
    // before the hasConfirmedWorkEmail was a number in the new state its a bool this is to ensure tracking is not broken
    workEmailConfirmed: userStore.hasConfirmedWorkEmail ? 1 : 0,
  }

  return {
    ecommerce: {
      viewItem: (item: EcommerceItem) => {
        $gtm?.trackEvent({ ecommerce: null }) // Clear the previous ecommerce object.
        $gtm?.trackEvent({
          ...basePayload,
          ...baseEcommerce([item], config.public.site.currency),
          event: 'view_item',
        })
      },

      viewItemList: (item_list_id: string, items: EcommerceItem[]) => {
        $gtm?.trackEvent({ ecommerce: null }) // Clear the previous ecommerce object.
        $gtm?.trackEvent({
          ...basePayload,
          ...baseEcommerce(items, config.public.site.currency),
          event: 'view_item_list',
          item_list_id,
        })
      },
      addToCart: (item: EcommerceItem) => {
        $gtm?.trackEvent({ ecommerce: null }) // Clear the previous ecommerce object.
        $gtm?.trackEvent({
          ...basePayload,
          ...baseEcommerce([item], config.public.site.currency),
          event: 'add_to_cart',
        })
      },
      removeFromCart: (item: EcommerceItem) => {
        $gtm?.trackEvent({ ecommerce: null }) // Clear the previous ecommerce object.
        $gtm?.trackEvent({
          ...basePayload,
          ...baseEcommerce([item], config.public.site.currency),
          event: 'remove_from_cart',
        })
      },
      beginCheckout: (items: EcommerceItem[]) => {
        $gtm?.trackEvent({ ecommerce: null }) // Clear the previous ecommerce object.
        $gtm?.trackEvent({
          ...basePayload,
          ...baseEcommerce(items, config.public.site.currency),
          event: 'begin_checkout',
        })
      },
      purchase: (items: EcommerceItem[]) => {
        $gtm?.trackEvent({ ecommerce: null }) // Clear the previous ecommerce object.
        $gtm?.trackEvent({
          ...basePayload,
          ...baseEcommerce(items, config.public.site.currency),
          event: 'purchase',
        })
      },
    },
    trackPaywall: () => {
      $gtm?.trackEvent({
        ...basePayload,
        event: 'trackPaywall',
        paywallAction: 'Paywall impression',
      })
    },

    job: {
      view: (id: number) => {
        $gtm?.trackEvent({
          ...basePayload,
          event: 'jobView',
          id,
        })
      },
      applyClick: (id: number | string) => {
        $gtm?.trackEvent({
          ...basePayload,
          event: 'jobApplyClick',
          id,
        })
      },
      listClick: (
        job: JobAdListDto,
        placement: string,
        highlightStatus: string
      ) => {
        $gtm?.trackEvent({
          ...basePayload,
          event: 'jobListClick',
          id: job.id,
          placement,
          eventLabel: job.title,
          highlightStatus: highlightStatus,
        })
      },
      listImpression: (ids: number[], placement: string) => {
        $gtm?.trackEvent({
          ...basePayload,
          event: 'jobListImpression',
          ids,
          placement,
        })
      },
    },

    podcast: {
      play: (articleId: string, title: string) =>
        $gtm?.trackEvent({
          event: 'podcast.play',
          articleId,
          title,
        }),
    },

    promotionClick: (
      id: string,
      name: string,
      creative: string,
      position: string
    ) =>
      $gtm?.trackEvent({
        ...basePayload,
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id,
                name,
                creative,
                position,
              },
            ],
          },
        },
      }),

    commercialSites: {
      contactMeOpen: (route: string) =>
        $gtm?.trackEvent({
          ...basePayload,
          event: `arena`,
          action: 'contactMeOpen',
          route,
        }),
      contactMeSubmit: (route: string, lead: object) =>
        $gtm?.trackEvent({
          ...basePayload,
          event: `arena`,
          action: 'submitContactMe',
          lead,
          route,
        }),
      joinEventOpen: (
        eventType: 'course' | 'network' | 'conference',
        route: string
      ) =>
        $gtm?.trackEvent({
          ...basePayload,
          event: `arena`,
          action: `${eventType}.openJoinEvent`,
          route,
        }),
      joinEventNextPage: (eventType: string, route: string, step: number) =>
        $gtm?.trackEvent({
          ...basePayload,
          event: `arena`,
          action: `${eventType}.nextPage`,
          route,
          step,
        }),
      joinEventSubmit: (
        eventType: 'course' | 'network' | 'conference',
        route: string,
        eventPeople?: EventPerson[]
      ) =>
        $gtm?.trackEvent({
          ...basePayload,
          event: `arena`,
          action: `${eventType}.submit`,
          route,
          participants: eventPeople,
        }),
    },

    trial: {
      tryPaywall: () =>
        $gtm?.trackEvent({
          ...basePayload,
          event: 'trial',
          action: 'tryPaywall',
        }),
      startTrialClick: () =>
        $gtm?.trackEvent({
          ...basePayload,
          event: 'trial',
          action: 'startTrialClick',
        }),
      createUser: () =>
        $gtm?.trackEvent({
          ...basePayload,
          event: 'trial',
          action: 'createUser',
        }),
      completeClient: () =>
        $gtm?.trackEvent({
          ...basePayload,
          event: 'trial',
          action: 'completeClient',
        }),
      activateUser: () =>
        $gtm?.trackEvent({
          ...basePayload,
          event: 'trial',
          action: 'activateUser',
        }),
      trialOver: () =>
        $gtm?.trackEvent({
          ...basePayload,
          event: 'trial',
          action: 'trialOver',
        }),
    },
    vaa: {
      complete: (electionId: number) =>
        $gtm?.trackEvent({
          event: 'vaa.complete',
          electionId,
        }),
      skippedSignup: (electionId: number) =>
        $gtm?.trackEvent({
          event: 'vaa.skippedSignup',
          electionId,
        }),
      electionPromoShown: (electionId: number) =>
        $gtm?.trackEvent({
          event: 'vaa.electionPromoShown',
          electionId,
        }),
    },
    article: {
      view: (article: ArticleDTO) => {
        $gtm?.trackEvent({
          ...basePayload,
          event: 'article.view',
          headline: article.headline,
          articleWriters: article.articleWriters?.map((w) => w.name),
          personWriters: article.personWriters?.map((w) => w.name),
          typeId: article.typeId,
          typeName: article.type?.name,
          urlKey: article.urlKey,
        })
      },
    },
  }
}
